<template>
    <div class="Address">
		<div class="toolbar">
			<el-button type="primary" size="small" @click="dataBtn('')">
				<Icon icon="Plus"></Icon>
				新增
			</el-button>
		</div>
		<el-table :data="tableData" v-loading="loading" border :height="height" @selection-change="handleSelectionChange">
			<el-table-column type="index" label="#" width="50" align="center"/>
			<el-table-column prop="asDefault" label="是否默认地址" width="150" align="center">
				<template #default="scope">
					<el-tag v-if="scope.row.asDefault == '1'">是</el-tag>
					<el-tag type="danger" v-else>否</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="asPerson" label="收件人" width="150" align="center" />
			<el-table-column prop="asMobile" label="电话" width="150" align="center" />
			<el-table-column prop="peName" label="省" align="center" width="250" />
			<el-table-column prop="cyName" label="市" align="center" width="250" />
			<el-table-column prop="dtName" label="区" align="center" width="250" />
			<el-table-column prop="asAddress" label="详细地址" align="center" width="350"/>
			<el-table-column label="操作" width="100" align="center" fixed="right">
				<template #default="scope">
					<el-tooltip
						class="box-item"
						effect="dark"
						content="编辑"
						placement="top"
					>
					    <span class="iconBtn" @click="dataBtn(scope.row)">
					    	<Icon icon="Edit"></Icon>
					    </span>
					</el-tooltip>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<el-tooltip
						class="box-item"
						effect="dark"
						content="删除"
						placement="top"
					>
					    <span title="" class="iconBtn" @click="delBtn(scope.row)">
					    	<Icon icon="Delete"></Icon>
					    </span>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;padding:8px;">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[30, 100, 200, 300]"
				:page-size="pageSize"
				:total="total"
				layout="total, sizes, prev, pager, next, jumper"
			>
			</el-pagination>
		</div>
		<el-drawer
		    v-model="drawer"
		    :title="title"
		    size="50%"
			:destroy-on-close="true"
			:show-close="false"
			:close-on-press-escape="false"
			:close-on-click-modal="false"
		>
		    <div class="drawerCon" :style="{'height':drawerHeight,'overflow-y':'auto','overflow-x':'hidden'}">
				<EditForm ref="EditForm" @onClose="onClose"></EditForm>
			</div>
			<div class="drawerFooter">
				<el-button @click="drawer = false">取消</el-button>
				<el-button type="primary" @click="saveBtn" :loading="loading">保存</el-button>
			</div>
		</el-drawer>
    </div>
</template>
<script>
	import Icon from "@/components/Icon"
	import EditForm from './Edit/Edit'
	export default {
		components:{Icon,EditForm},
		data() {
			return{
				drawer:false,
				loading:false,
				height:'600px',
				drawerHeight:'',
				title:'新增',
				currentPage: 1,
				total: 0,
				pageSize:30,
				tableData:[],
			}
		},
		created(){
			this.initData();
		},
		methods:{
			saveBtn(){
				let that = this;
				that.loading = true;
				that.$refs.EditForm.submitForm("ruleForm");
			},
			delBtn(row){
				let that = this;
				this.$confirm('确定要删除吗?删除将无法恢复', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
				.then(() => {
					that.$axios({
						method:'post',
						headers: {
							token: window.localStorage.getItem("token")
						},
						url: 'user/as/delete',
						data:this.$qs.stringify({
							asId: row.asId
						})
					})
					.then((res)=>{
						let msg = res.data.msg;
						if(msg==2){
							that.$message.success("删除成功！");
							that.initData();
						}else{
							that.$message.error(msg);
						}
					})
					.catch((err)=>console.log('请求失败',err))
				})
				.catch(() => {});
			},
			dataBtn(row){
				let that = this;
				if(row == ""){
					that.title = "新增";
				}else{
					that.title = "修改";
				}
				that.drawer = true;
				that.$nextTick(()=>{
					that.$refs.EditForm.initData(row);
				})
			},
			onClose(index){
				let that = this;
				if(index = '0'){
					that.drawer = false;
					that.initData();
				}
				that.loading = false;
			},
			initData(){
				let that = this;
				that.loading = true;
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url:'user/as/getList',
					data:this.$qs.stringify({
						urId: window.localStorage.getItem("urId"),
						page: that.currentPage,
						limit: that.pageSize
					})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					that.total = res.data.totalCounts;
					that.tableData = rows;
					that.loading = false;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			handleSizeChange(val){
			   this.pageSize = val;
			   this.currentPage = 1;
			   this.initData();
			},
			handleCurrentChange(val){
			   this.currentPage = val;
			   this.initData();
			},
			setHeight(){
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				this.height = h - 400 + 'px';
				this.drawerHeight = h - 147 + 'px';
			}
		},
		beforeMount(){
			this.setHeight();
		},
		mounted(){
			let that = this;
			window.addEventListener('resize',() => that.setHeight(), false);
		},
		beforeDestroy(){
			window.removeEventListener('resize', this.setHeight(), false)
		}
	};
</script>
<style scoped>
	.Address{
		padding:10px;
		box-sizing:border-box;
	}
	.toolbar{
		margin-bottom:10px;
	}
</style>
